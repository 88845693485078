import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/Layout'
import Post from '../../components/Post'
import DateHeader from '../../components/DateHeader'

const BlogPost = ({ data }) => {
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <Post>
        <DateHeader>{data.mdx.frontmatter.date}</DateHeader>
        <h1>{data.mdx.frontmatter.title}</h1>
        <MDXRenderer>
          {data.mdx.body}
        </MDXRenderer>
      </Post>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      body
      id
      frontmatter {
        date
        title
      }
    }
  }
`

export default BlogPost